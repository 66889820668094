/* Add these styles to your CSS file */

.card-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}

.card-animation.visible {
  opacity: 1;
  transform: translateY(0);
}

  