/* Dashboard.css */
.dashboard-container {
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 28px;
  font-weight: bold;
}

.results-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
}

.results-table th, .results-table td {
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  text-align: center;
  font-weight: 500;
}

.results-table th {
  background-color: #f2f2f2;
  color: #555;
  font-size: 18px;
}

.results-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

@media (max-width: 768px) {
  h2 {
    font-size: 24px;
  }

  .results-table {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  h2 {
    font-size: 20px;
  }

  .results-table {
    font-size: 12px;
  }
}
